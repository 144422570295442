import ErrorUtil from "../../classes/ErrorUtil";
import mockData from "../../test/mockData";

export default {
    namespaced: true,
    state() {
        return {
            category: {},
            categories: [],
            totalResults: 0,
            categories2: [],
            categories3: [],
            categories23: [],
            totalResults2: 0,
            totalResults3: 0,
            totalResults23: 0,
            specs: {},
            specsMeta: {},
            product: {
                name: null
            },
            favorites: [],
            categoryLoaded: false,
            categoriesLoaded: false,
            categories2Loaded: false,
            categories3Loaded: false,
            categories23Loaded: false,
            specsLoaded: false,
            favoritesLoaded: false,

            itemsXml: false,
            itemsXmlLoaded: false,
        }
    },
    actions: {
        async loadCategories(context) {
            if (context.rootGetters['app/offlineMode']) {
                context.commit('setMockCategories');
                return;
            }

            await context.rootGetters['app/axios'].get('/category2', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    slug: "VIDES",
                    lang: context.rootGetters['app/locale']
                }
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            })
            .then((response) => {
                const categories =  response.data.data;
                const totalResults = response.data.meta ? response.data.meta.total : response.data.length;
                context.commit('setCategories', {
                    data: categories,
                    total: totalResults
                });
            });
        },


        async loadCategories2(context, payload) {
            await context.rootGetters['app/axios'].get('/category2', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    slug: "TOOLS",
                    lang: context.rootGetters['app/locale'],
                    toolbrand: payload.toolbrand?? null,
                }
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            })
            .then((response) => {
                const categories2 =  response.data.data;
                const totalResults2 = response.data.meta ? response.data.meta.total : response.data.length;
                context.commit('setCategories2', {
                    data: categories2,
                    total: totalResults2
                });
                console.log("setCategories2...");
            });
        },


        async loadCategories3(context, payload) {
            await context.rootGetters['app/axios'].get('/category2', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    slug: "MAKITA",
                    lang: context.rootGetters['app/locale'],
                    toolbrand: payload.toolbrand?? null,
                }
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            })
            .then((response) => {
                const categories3 =  response.data.data;
                const totalResults3 = response.data.meta ? response.data.meta.total : response.data.length;
                context.commit('setCategories3', {
                    data: categories3,
                    total: totalResults3
                });
                console.log("setCategories3...");
            });
        },

        async loadCategories23(context, payload) {
            await context.rootGetters['app/axios'].get('/category2', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    slug: "ALLTOOLS",
                    lang: context.rootGetters['app/locale'],
                    toolbrand: payload.toolbrand?? null,
                }
            })
            .catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            })
            .then((response) => {
                const categories23 =  response.data.data;
                const totalResults23 = response.data.meta ? response.data.meta.total : response.data.length;
                context.commit('setCategories23', {
                    data: categories23,
                    total: totalResults23
                });
                console.log("setCategories23...");
            });
        },



        async loadCategoryDetails(context, payload) {
            await context.rootGetters['app/axios'].get('/category2', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    slug: payload.slug,
                    lang: context.rootGetters['app/locale'],
                    toolbrand: payload.toolbrand
                }
            })
                .catch(function (error) {
                    const errorMessage = new ErrorUtil(error).composeMessage();
                    throw new Error(errorMessage);
                })
                .then((response) => {
                    if (response.data.result !== 'success') {
                        throw new Error("Error");
                    }
                    let category;
                    category = response.data.meta;
                    category.children = response.data.data;
                    context.commit('setCategory', category);
                });
        },
        async loadCategoryProducts(context, payload) {
            if (context.rootGetters['app/offlineMode'] || payload.useMockData === true) {
                context.commit('setMockCategoryProducts');
                return;
            }

            await context.rootGetters['app/axios'].get('product', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    slug: payload.slug,
                    lang: context.rootGetters['app/locale'],
                    dim1: payload.dim1,
                    dim2: payload.dim2,
                    itemcode: payload.itemcode,
                }
            }).then((response) => {
                let category;

                category = response.data.meta;
                category.products = response.data.data.products;
                category.product_groups = response.data.data.product_groups;

                context.commit('setCategory', category);

            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadProductSpecs(context, productId) {
            await context.rootGetters['app/axios'].get('productspecs', {
                params: {
                    id: productId,
                    lang: context.rootGetters['app/locale']
                }
            }).then((response) => {
                const specs = response.data.data;
                const specsMeta = response.data.meta;
                const productName = response.data.meta.product_name;

                context.commit('setSpecs', specs);
                context.commit('setSpecsMeta', specsMeta);
                context.commit('setProductName', productName);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async addToFavourites(context, itemId) {
            await context.rootGetters['app/axios'].post('favorites/add', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    item: itemId
                }
            }).then((response) => {
                if (response.data.result === 'success') {
                    const favorites = response.data.data;

                    context.commit('setFavourites', favorites);
                }
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async removeFromFavourites(context, itemId) {
            await context.rootGetters['app/axios'].post('favorites/remove', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                    item: itemId
                }
            }).then((response) => {
                if (response.data.result === 'success') {
                    const favorites = response.data.data;

                    context.commit('setFavourites', favorites);
                }
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async loadFavourites(context) {
            await context.rootGetters['app/axios'].post('favorites', {
                data: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                }
            }).then((response) => {
                // console.log('favourites response: ', response.data);

                const favorites = response.data.data;

                context.commit('setFavourites', favorites);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
        async emptyFavourites(context) {
            await context.rootGetters['app/axios'].get('favorites/empty', {
                params: {
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale'],
                }
            }).then(() => {
                context.commit('setFavourites', []);
            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },

        async getItemsXml(context, payload) {
            //getitemsxml
            await context.rootGetters['app/axios'].get('getitemsxml', {
                params: {
                    all_items: payload.all_items,
                    fasteners: payload.fasteners,
                    tools: payload.tools,
                    category_ids: payload.category_ids,
                    category_ids_fasteners: payload.category_ids_fasteners,
                    category_ids_tools: payload.category_ids_tools,
                    accesstoken: context.rootGetters['auth/token'],
                    lang: context.rootGetters['app/locale']
                }
            }).then((response) => {
                const items = response.data.data;
                context.commit('setItemsXml', items);

            }).catch(function (error) {
                const errorMessage = new ErrorUtil(error).composeMessage();
                throw new Error(errorMessage);
            });
        },
    },
    mutations: {
        clearCache(state) {
            state.favorites = [];
            state.favoritesLoaded = false;
        },
        setCategories(state, payload) {
            state.categories = payload.data;
            state.totalResults = payload.total;
            state.categoriesLoaded = true;
        },
        setCategories2(state, payload) {
            state.categories2 = payload.data;
            state.totalResults2 = payload.total;
            state.categoriesLoaded2 = true;
        },
        setCategories3(state, payload) {
            state.categories3 = payload.data;
            state.totalResults3 = payload.total;
            state.categoriesLoaded3 = true;
        },
        setCategories23(state, payload) {
            state.categories23 = payload.data;
            state.totalResults23 = payload.total;
            state.categoriesLoaded23 = true;
        },
        setCategory(state, category) {
            state.category = category;
            state.categoryLoaded = true;
        },
        // setCategoryProducts(state, category) {
        //     state.category = category;
        //     state.categoryLoaded = true;
        // },
        setSpecs(state, specs) {
            state.specs = specs;
            state.specsLoaded = true;
        },
        setSpecsMeta(state, specsMeta) {
            state.specsMeta = specsMeta;
        },
        setProductName(state, productName) {
            state.product.name = productName;
        },
        // setFetchTimestamp(state) {
        //     state.lastFetch = new Date().getTime();
        // },
        setMockCategories(state) {
            state.categories = mockData.categories;
            state.totalResults = mockData.categories.length;
            state.categoriesLoaded = true;
        },
        setMockCategoryProducts(state) {
            state.category = mockData.categoryProducts;
            state.categoryLoaded = true;
        },
        setFavourites(state, favorites) {
            state.favorites = favorites;
            state.favoritesLoaded = true;
        },
        setItemsXml(state, items) {
            state.itemsXml = items;
            state.itemsXmlLoaded = true;
        }
    },
    getters: {
        categories(state) {
            return state.categories;
        },
        categories2(state) {
            return state.categories2;
        },
        categories3(state) {
            return state.categories3;
        },
        categories23(state) {
            return state.categories23;
        },

        category(state) {
            return state.category;
        },
        categoryLoaded(state) {
            return state.categoryLoaded;
        },
        totalResults(state) {
            return state.totalResults;
        },
        totalResults2(state) {
            return state.totalResults2;
        },
        totalResults3(state) {
            return state.totalResults3;
        },
        totalResults23(state) {
            return state.totalResults23;
        },
        // hasCategories(state) {
        //     return state.categories && state.categories.length > 0;
        // },
        categoriesLoaded(state) {
            return state.categoriesLoaded;
        },
        categories2Loaded(state) {
            return state.categories2Loaded;
        },
        categories3Loaded(state) {
            return state.categories3Loaded;
        },
        categories23Loaded(state) {
            return state.categories23Loaded;
        },
        specs(state) {
            return state.specs;
        },
        specsMeta(state) {
            return state.specsMeta;
        },
        product(state) {
            return state.product;
        },
        specsLoaded(state) {
            return state.specsLoaded;
        },
        favorites(state) {
            return state.favorites;
        },
        favoritesLoaded(state) {
            return state.favoritesLoaded;
        },
        itemsXml(state) {
            return state.itemsXml;
        },
        itemsXmlLoaded(state) {
            return state.itemsXmlLoaded;
        },
    },
}
