<template>
  <div class="rounded-lg border-1 cart-item-container" style="margin-bottom:10px;" :style="{'background-color': bgcolor}">
    <div class="flex flex-col rounded-lg-xx p-4 manrope font-semibold cart-item-inner-1" 
      style="background-color: #efefe2 /*#F5F5E8*/; position:relative; border-bottom:1px solid">

      <p class="cart-item-description text-medium">{{ item.item_description }}</p>
      <p class="cart-item-code text-semibold">{{ item.item_code }}</p>

      <span class="text-15 text-medium gp-checkout-item-remove" 
        @click.stop="removeItem(item)">
        {{ $t('labels.remove') }}
      </span>

      <!--<div class="cart-item-availability" v-if="!availability" :style="{color: availabilityColor}">{{availabilityLabel}}</div>-->

      <div class="grid grid-cols-2 md:grid-cols-4 gap-2 text-medium cart-line-info" style="">
        <div class="col-span-1  cart-item-box flex items-center justify-center cart-item-availability" v-if="!availability" :style="{background: availabilityColor}">{{availabilityLabel}}</div>
        <div v-else class="col-span-1"></div>
        <div class="col-span-1"></div>
        <div class="col-span-1"></div>
        <div class="col-span-1"></div>
        
        <div class="col-span-1  cart-item-box flex items-center justify-center">
          {{ item.quantity_pcs }} {{ $t('labels.pcs') }}
        </div>

        <div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.pcs-per-package') }}: {{ item.package_description }}
        </div>

        <div class="col-span-2 cart-item-box cart-item-box-plus-minus flex items-center justify-center" style="padding: 0;">
          <div class="review-cart-packages p-3 mr-2-xx" style="width: 55%">
            {{ item.quantity_packages }} {{ $t('labels.packages') }} 
          </div>
          <div @click="$emit('add-package', item)"
              style="width: 30%; margin-left:-5%"
              class="review-cart-add flex items-center justify-center h-full gp-p-5 px-3 cursor-pointer text-2xl border-l-1 border-r-1 rounded-md">
            +
          </div>
          <div @click="$emit('subtract-package', item)"
              style="width: 25%"
              class="review-cart-minus flex items-center justify-center h-full py-2-- gp-p-5 px-3 cursor-pointer text-2xl rounded-md">
            -
          </div>
        </div>

        <div class="col-span-2 cart-item-box flex items-center justify-center">
          {{ $t('labels.initial-price') }}: {{ prettyNum(item.unit_price) }} {{ $t('labels.per') }} {{ item.unit_description }}
        </div>

        <div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.discount') }}: {{ numParse(item.discount_1) }}% + {{ numParse(item.discount_customer) }}%
        </div>

        <div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.final-price') }}: {{ prettyNum(item.final_price2) }}
        </div>

        <!--<div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.discount2') }} / {{ numParse(item.discount_2) }}% 
        </div>-->

        <!--<div class="col-span-1 cart-item-box flex items-center justify-center">
          {{ $t('labels.discount_total') }} / {{ numParse(item.discount) }}% 
        </div>-->      

        

      </div>
    </div>

    <div class="p-4">
      <div class="grid grid-cols-2 md:grid-cols-3 gap-2 text-medium cart-line-info" style="">
        <div class="col-span-1 cart-item-box flex items-center justify-center">
            {{ $t('labels.total-line') }}: {{ prettyNum(item.total_price2) }}
          </div>

          <div class="col-span-1 cart-item-box flex items-center justify-center">
            {{ $t('labels.vat') }}: {{ prettyNum(item.vat_value2) }}
          </div>

          <div class="col-span-1 cart-item-box flex items-center justify-center">
            {{ $t('labels.total-w-vat') }}: {{ prettyNum(item.total_price2 + item.vat_value2) }}
          </div>

      </div>
    </div>

    <div class="cart-item-img">
      <img :src="item.photo" style="" />
      <div class="item-index text-medium">{{ item.index }}</div>
    </div>

    <div class="item-index-mobile text-medium">{{ item.index }}</div>
    

  </div>
  
</template>

<script>
export default {
  name: "CartOverlayPanelItem",
  inject: ['prettyNum'],
  emits: ['add-package', 'subtract-package'],
  props: {
    item: {
      type: Object,
      required: true,
    },
    bgcolor: {
      type: String,
    }
  },
  data() { /** gp */
    return {
      quantity: 0,
      available: true,
      inventoryTotal: 0,
    }
  },
  computed: {
    availability() {
      if (this.item.inventory_total >= this.item.quantity_pcs) {
        return true;
      }
      else {
        return false;
      }
    },
    availabilityLabel() {
      /*if (this.item.inventory_total >= this.item.quantity_pcs) {
        return "Available";
      }
      else {
        return this.$t('labels.quantity-not-available');
      }*/
      if (this.item.product_type=='fastener') {
        return this.item.inventory_total >= this.item.quantity_pcs ? this.$t('labels.available') : this.$t('labels.limited-availability');
      }
      else if (this.item.product_type=='tool') {
        return this.item.inventory_total >= this.item.quantity_pcs ? this.$t('labels.available') : this.$t('labels.available-in-15-days');
      }
      else {
        return this.item.inventory_total >= this.item.quantity_pcs ? this.$t('labels.available') : this.$t('labels.limited-availability');
      }
    },
    availabilityColor() {
      if (this.item.inventory_total >= this.item.quantity_pcs) {
        return "green";
      }
      else {
        return "#ff5f00";
      }
    }
  },
  methods: {
    numParse(x) {
      if (x === undefined || x === null) {
        return '';
      }

      if (typeof x === 'number') {
        return x;
      }

      return x; // parseFloat(x.replace('.', ''));
    },
    async removeItem(item) {
      await this.$store.dispatch('cart/updateItems', {
        item_code: item.item_code,
        item_quantity: 0
      });
    },
  }
}
</script>

<style scoped>
  .cart-item-box {
    border: 1px solid #212121;
    border-radius: .75rem;
    text-align: center;
    width: 100%;
    /*padding: .75rem;*/
    padding: 12px 5px;
    font-size:14px;
    line-height:16px;
  }

  .cart-item-description {
    font-size: 26px;
    line-height: 30px;
    /*font-weight: normal;*/
    padding-left:15px;
    max-width:calc(100% - 100px);
    margin-bottom:10px;
  }
  .cart-item-code {
    font-size:13px;
    padding-left:15px;
    margin-bottom:20px;
  }

  

  @media (max-width: 440px) {
    .gp-checkout-item-remove {
      font-weight: 700;
    }
  }

  @media (min-width: 800px) {
    .cart-item-box {
      background-color: #FFFCF0;
    }

    

    .cart-item-box-plus-minus {
      background-color: #E6E5DA;
    }

    .review-cart-packages {
      /*border-right: 1px solid #212121;*/
      border-radius: .75rem;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      background-color: #FFFCF0;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }

    .review-cart-add {
      background-color: #E6E5DA;
      /*margin-left:-5%;*/
      /*border-left: 0;*/
    }

    .review-cart-minus {
      background-color: #EFEFE2;
      border-top-right-radius: .75rem;
      border-bottom-right-radius: .75rem;
    }
  }

  /*.review-cart-add:hover,*/
  /*.review-cart-add:focus,*/
  /*.review-cart-add:active,*/
  /*.review-cart-minus:hover,*/
  /*.review-cart-minus:focus,*/
  /*.review-cart-minus:active {*/
  /*  background-color: #212121;*/
  /*  color: #fff;*/
  /*}*/

  @media screen and (max-width:800px) {
    .cart-item-box {
      font-size:13px;
      line-height:15px;
      min-height:40px;
    }

    .cart-item-description {
      font-size: 20px;
      font-weight: 500;
      padding-left:10px;
    }
  }

  .cart-line-info {
    padding-left:15px;
  }
  .cart-item-availability {
    font-size:14px;
    translate:0px 9px;
    color:#fff;
  }

  @media screen and (max-width:800px) {
  .cart-line-info,
  .cart-item-availability {
    padding-left:0px;
  }  
  }


  .gp-checkout-item-remove {
    position:absolute; 
    top:25px; 
    right:20px; 
    cursor:pointer; 
  }

  .gp-checkout-item-remove:hover {
    text-decoration: underline;
  }

  @media screen and (max-width:440px) {
    .review-cart-add{background-color: #E5E4D9;}
    .cart-item-box{background-color: #FDFBEF;}
  }

  .cart-item-container {
    background-image:url(../../assets/svg/octagon-bg-checkout.svg);
    background-size: 100% 100%;
    padding:1px;
    border:none;
    border-radius:10px;
    position: relative;
  }

  .cart-item-img {
    position: absolute;
    top:0px;
    left:-250px;
    height:100%;
    width:270px;
    /*border:1px solid #ccc;
    border-radius: 15px;*/
    z-index: -1;
    padding:20px 40px 20px 20px;
    background-image:url(../../assets/svg/octagon-bg-checkout.svg);
    background-size: auto 100%;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  .item-index,
.item-index-mobile {
    position:absolute;
    
    width:37px;
    height:34px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-image:url(../../assets/svg/hexagon-icon-bg.svg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color:#fff;
    font-size: 20px;
  }
  .item-index {
    top:0px;
    left:-50px;
  }
  .item-index-mobile {
    bottom:10px;
    right:10px;
  }
  @media (min-width:1001px) {
    .item-index-mobile {
      display:none;
    }
  }

  @media (max-width:1000px) {
    .cart-item-img {
      display:none;
    }
  }

  .cart-item-inner-1 {
    border-radius:15px;
  }

</style>