<template>
  <arrow-filters class="gp-arrow-filters-mb"/>


    <!-- all sizes -->
  <subcategories-header
      class="--sm:hidden"
      :category="category"
      :subcategories="null"
      :selected-subcategory="null"
      :p1="p1"
      :r1="r1"
      :p2="p2"
      :r2="r2"
      :toolbrand="toolbrand"
      style="margin-top:-35px"
  ></subcategories-header>


  <!-- mobile /////////////////////////////////////////////////////////////////////////-->
  <div v-if="dataHasBeenLoaded && isMobile" class="lg:hidden flex flex-col gp-pt-20 mobile-app-cancel-px" style="">
    

    <!--<blue-polygon-links :category="category"/>-->

    <div class="flex justify-end space-x-2 gp-px-20 sm:p-6" style="">        
      <img v-if="isFavorite" title="Unmark as favorite" src="../../assets/svg/heart-filled.svg" class="gp-h-30 cursor-pointer gp-heart" @click="unmarkAsFavorite"/>
      <img v-else title="Mark as favorite"  src="../../assets/svg/heart.svg" class="gp-h-30 cursor-pointer gp-heart" @click="markAsFavorite"/>

      <a class="relative specs-mobile" :href="specsLink">
        <img src="../../assets/svg/specs.svg" class="top-0 right-0 blue-hexagon"/>
        
      </a>
      
    </div>

    <!--<img :src="category.image_url" class="h-48 w-48 mt-16 mb-16 mx-auto"/>-->

    <div class="brdr-3d" style="">
        <sketch-fab3d-viewer
            v-if="categoryHas3dLink"
            :link="category3dLink"
      />
      <img
          v-else
          :src="category.image_url" class="h-48 w-48 mt-16 mb-16 mx-auto"
      />
    </div>
    


    <div class="brdr-prd"  style="">
      <div class="text-31 txt-lh-42 text-medium text-darkgray px-4-- mb-4 gp-px-22 ttl-mob">
        {{ category.category_name }}
      </div>

      <products-accordion
          :category="category"
          :local-items="localItems"
      ></products-accordion>
    </div>
    


    <div class="col-span-12"></div>

    <div class="col-span-12">
      <div class="prdcts-brdr" style="min-height:200px; margin-top:22px">
        <product-order 
          :local-items="localItems"
          ></product-order>
      </div>
    </div>


    <!-- With this product you may need  - mobile-->
    <div v-if="category.related_categories.length" 
    class="slidr-title text-regular manrope text-darkblue mb-4 gp-px-22"
    style="font-size:29px; line-height:43px; margin-top:90px; ">
      {{ $t('labels.you-also-need')}}
    </div>

    
    <div v-if="category.related_categories.length"
         class="mobile-app-px-xx mobile-app-cancel-px-xx py-8 mb-16 rlt-mob" style="background-color: #F0EFE2;">
      <custom-slider
          ref="youAlsoNeedSlider"
          class="you-also-need-slider home-margin-left"
          :slide-count="category.related_categories.length"
          :slide-width="responsiveSlideWidth"
          :slide-height="360"
          :scrollbar="isMobile"
          :corner-prev-next="true"
          :debug="false"
      >
        
        <product-slider-item
          v-for="(yanCategory, index) in category.related_categories"
          :key="yanCategory.id"
          :item="yanCategory"
          :ref="'yan-slide-'+yanCategory.id"
          :index="index"
        ></product-slider-item>

      </custom-slider>
    </div>

    <!-- Recently ordered -->
    <div v-if="auth && category.recently_ordered_items.length" class=" text-regular manrope text-darkblue mb-4 gp-px-22"
    style="font-size:29px; line-height:43px">
      {{ $t('labels.recently-ordered')}}
    </div>

    <div v-if="auth && category.recently_ordered_items.length"
         class="mobile-app-px mobile-app-cancel-px py-8 mb-16" style="margin-bottom:90px">
      <custom-slider
          ref="recentlyOrderedSlider"
          class="recently-ordered-slider home-margin-left"
          :slide-count="category.recently_ordered_items.length"
          :slide-width="responsiveSlideWidth"
          :slide-height="360"
          :debug="false"
      >
        
        

        <product-slider-item
          v-for="(roCategory, index) in category.recently_ordered_items"
          :key="roCategory.id"
          :item="roCategory"
          :ref="'ro-slide-'+roCategory.id"
          :index="index"
        ></product-slider-item>



      </custom-slider>
    </div>

    <div class="flex justify-end mb-8 hidden">
      <div class="flex flex-col text-md justify-center pr-6 manrope">
        <div class="text-darkblue">{{ $t('labels.need-help') }}</div>
        <div class="">{{ $t('labels.ask-a-specialist') }}</div>
      </div>
      <div>
        <img height="100" width="100" class="rounded-full" src="../../assets/images/fastener-28.png"/>
      </div>
    </div>

  <!-- end mobile -->
  </div>


  <!-- Desktop ################################################################## -->
  <div v-if="dataHasBeenLoaded && !isMobile" class="hidden-xx lg:grid-xx grid grid-cols-12 mx-16 mb-16" style="/*background-color: #F5F5E8;*/ min-height:500px">
    <!-- 3D viewer -->
    <div class="col-span-12 lg:col-span-6 xl:col-span-6" style="position:relative">
      <div v-if="categoryHas3dLink" class="product-image-container img-sticky" > <!--:class="productImgClass"-->
        <sketch-fab3d-viewer
            
            :link="category3dLink"
        />
        

        <img v-if="isFavorite" title="Unmark as favorite" src="../../assets/svg/heart-filled.svg" class="gp-h-30 cursor-pointer gp-heart" @click="unmarkAsFavorite"/>
        <img v-else title="Mark as favorite" src="../../assets/svg/heart.svg" class="gp-h-30 cursor-pointer gp-heart" @click="markAsFavorite"/>

        <!-- Local Items: {{ localItems }}-->
      </div>
      <div v-else class="product-image-container-white img-sticky" > <!--:class="productImgClass"-->
        
        <img            
            :src="category.image_url" class="h-auto w-auto mt-32-xx mb-2 mx-auto img-sticky-xxx" style="max-height: 600px"
        />

        <img v-if="isFavorite" title="Unmark as favorite" src="../../assets/svg/heart-filled.svg" class="gp-h-30 cursor-pointer gp-heart" @click="unmarkAsFavorite"/>
        <img v-else title="Mark as favorite" src="../../assets/svg/heart.svg" class="gp-h-30 cursor-pointer gp-heart" @click="markAsFavorite"/>

        <!-- Local Items: {{ localItems }}-->
      </div>
    </div>
    

    <!-- Rest interface -->
    <div class="col-span-12 lg:col-span-6 xl:col-span-6">
      
      <div id="productinfo" ref="productinfo"
        :class="productInfoClass" 
        class="flex flex-col col-span-12-xx lg:col-span-6-xx xl:col-span-6-xx">
        
        <div class="flex justify-end space-x-2 gp-px-20-xx sm:p-6-xx" style="padding:20px 20px 0px">
          <a class="relative" :href="specsLink">
            <!--<img src="../../assets/svg/specs.svg" class="top-0 right-0 h-8-- blue-hexagon"/>-->
            <div class="specs"></div>
          </a>
        </div>


        <!--<subcategories-header
            class="gp-back-link"
            :category="category"
            :subcategories="null"
            :selected-subcategory="null"
            :p1="p1"
            :r1="r1"
            :p2="p2"
            :r2="r2"
        ></subcategories-header>-->



        <div class="text-semibold manrope text-dark-gray mt-12 pl-4 dt-product-name">
          {{ category.category_name }}
        </div>
        

        <products-accordion
            :category="category"
            :local-items="localItems"
            :max_productname_len = "category.max_productname_len"
        ></products-accordion>

        


      </div>

      <div style="height:20px"></div>

      <div class="col-span-12 lg:col-span-6">
        <div style="min-height:20px; margin-top:22px">
          <product-order 
            :local-items="localItems"
            ></product-order>
        </div>
      </div>

    </div>

    

    <div v-if="dimensionsFilter()"
      class="flex-col col-span-12" 
      style="width:100%;text-align:right; padding:10px">      
      <a :href="productUrl()">{{ $t('labels.product_view_all_dim') }}</a>
    </div>

    <div class="col-span-12 lg:col-span-6"></div>

    <!--<div class="col-span-12 lg:col-span-6">
      <div style="min-height:20px; margin-top:22px">
        <product-order 
          :local-items="localItems"
          ></product-order>
      </div>
    </div>-->

  </div>

  

  <!-- Desktop II -->
  <div v-if="dataHasBeenLoaded && !isMobile" class="hidden-xx lg:block-xx mb-16 " style="padding-top:50px">
    <!-- With this item you will need  - desktop-->
    <div v-if="category.related_categories.length"
         class="title-related-products text-semibold manrope text-dark-gray mb-10 home-margin-left">
      {{ $t('labels.you-also-need')}}
    </div>
    
    <custom-slider
        ref="relatedCategoriesSlider"
        class="related-categories-slider home-margin-left"
        :slide-count="category.related_categories.length"
        :slide-width="responsiveSlideWidth2"
        :slide-height="360"
        :scrollbar="isMobile"
        :corner-prev-next="true"
        :debug="false"
    >
        <product-slider-item
          v-for="(yanCategory, index) in category.related_categories"
          :key="yanCategory.id"
          :item="yanCategory"
          :ref="'yan-slide-'+yanCategory.id"
          :index="index"
        ></product-slider-item>

    </custom-slider>



    <div class="spacer-after-related-products"></div>

    <!-- Recently ordered -->
    <div v-if="auth && category.recently_ordered_items.length"
         class="text-dark-gray mb-10 title-recently-ordered text-semibold manrope home-margin-left" >
      {{ $t('labels.recently-ordered')}}
    </div>

    <custom-slider v-if="auth && category.recently_ordered_items.length"
        ref="recentlyOrderSlider"
        class="recently-ordered-slider home-margin-left"
        :slide-count="category.recently_ordered_items.length"
        :slide-width="responsiveSlideWidth2"
        :slide-height="360"
        :scrollbar="isMobile"
        :corner-prev-next="true"
        :debug="false"
    >
        <product-slider-item
          v-for="(roCategory, index) in category.recently_ordered_items"
          :key="roCategory.id"
          :item="roCategory"
          :ref="'ro-slide-'+roCategory.id"
          :index="index"
        ></product-slider-item>
    </custom-slider> 

    <div class="spacer-after-recently-bought-products"></div>

  </div>
</template>

<script>

//import BluePolygonLinks from "@/components/products/BluePolygonLinks";
import ProductsAccordion from "@/components/products/ProductsAccordion";
import SketchFab3dViewer from "@/components/misc/SketchFab3dViewer";
import ArrowFilters from "@/components/ArrowFilters";
import ProductSliderItem from "@/components/custom-slider/ProductSliderItem";
import CustomSlider from "@/components/custom-slider/CustomSlider";
//import ProductPageTile from "@/components/misc/ProductPageTile";

import SubcategoriesHeader from "@/components/misc/SubcategoriesHeader";
//import SubcategorySlider from "@/components/misc/SubcategorySlider";
import ProductOrder from "@/components/products/ProductOrder";

export default {
  name: "CategoryProducts",
  components: {
    /*ProductPageTile,*/
    CustomSlider,
    ProductSliderItem,
    ArrowFilters,
    SketchFab3dViewer,
    ProductsAccordion,
    /*BluePolygonLinks,*/
    SubcategoriesHeader,
    /*SubcategorySlider*/
    ProductOrder
  },
  provide() {
    return {
      updateLocalItemQuantity: this.updateLocalItemQuantity,
      resetLocalItems: this.resetLocalItems,
    }
  },
  inject: ['openDialog'],
  props: {
    slug: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      dataHasBeenSet: false,
      category: {
        // ...
        products: [],
        productGroups: [],
        is_favorite: false,
        id: null,
      },
      localItems: [],
      max_productname_len: 0,
      productInfoClass: "product-info",
      productImgClass: "product-image-container"
      
    }
  },
  watch: {
    slug() {
      this.loadData();
    }
  },
  computed: {
    auth() {
      return this.$store.getters['auth/auth'];
    },
    dataHasBeenLoaded() {
      return this.$store.getters['categories/categoryLoaded'] && this.dataHasBeenSet;
    },
    categoryHas3dLink() {
      return this.category._3d_model !== "";
    },
    category3dLink() {
      return this.category._3d_model;
    },
    responsiveSlideWidth() {
      if (window.innerWidth <= 590) {
        return window.innerWidth - 40;
      } else {
        return 320 + 20;
      }
    },
    responsiveSlideWidth2() {
      return 214;
      /*let w = window.innerWidth;
      if (w<590) {
        return w-40;
      }
      else if (w<1300) {
        return 300;
      }
      else if (w<1400) {
        return 230;
      }
      else if (w<1600) {
        return 260;
      }
      else if (w<1800) {
        return 300;
      }
      else if (w<2200) {
        return 360;
      }
      else {
        return 400;
      }*/

    },
    isMobile() {
      return window.innerWidth <= 800;
    },
    isFavorite() {
      return this.category.is_favorite;
    },
    specsLink() {
      let this_url = document.URL;
      if (this_url.indexOf('/?')>0) {
        return this.$router.resolve(this.$route.path + 'specs').href;
      }
      else {
        return this.$router.resolve(this.$route.path + '/specs').href;
      }
    },
    p1() {
      return typeof(this.$route.query.p1)=="undefined"? "": this.$route.query.p1;
    },
    r1() {
      return typeof(this.$route.query.r1)=="undefined"? "": this.$route.query.r1;
    },
    p2() {
      return typeof(this.$route.query.p2)=="undefined"? "": this.$route.query.p2;
    },
    r2() {
      return typeof(this.$route.query.r2)=="undefined"? "": this.$route.query.r2;
    },
    toolbrand() {
      return typeof(this.$route.query.toolbrand)=="undefined"? "": this.$route.query.toolbrand;
    },
    
    
  },
  methods: {
    dim1() {
      var myDim1 = typeof(this.$route.query.d)=="undefined"? "": this.$route.query.d;
      console.log('myDim1=' + myDim1);
      return myDim1;
    },
    dim2() {
      var myDim2 = typeof(this.$route.query.l)=="undefined"? "": this.$route.query.l;
      console.log('myDim2=' + myDim2);
      return myDim2;
    },
    itemcode() {
      var my_itemcode = typeof(this.$route.query.itemcode)=="undefined"? "": this.$route.query.itemcode;
      console.log('my_itemcode=' + my_itemcode);
      return my_itemcode;
    },
    dimensionsFilter() {
      var myVar = this.dim1()!="" || this.dim2()!="" || this.itemcode()!="";
      console.log('myVar=' + myVar);
      return myVar;
    },
    productUrl() {
      //$store.getters['app/locale'] === 'el'?"": "en" +  '/product/' + slug
      var mylang = this.$store.getters['app/locale'] === 'el'? "/": "en/";
      return '' + mylang + 'product/' + this.slug;
    },
    
    async loadData() {
      try {
        await this.$store.dispatch('categories/loadCategoryProducts', {
          slug: this.slug,
          useMockData: false,
          dim1: this.dim1(),
          dim2: this.dim2(),
          itemcode: this.itemcode(),
        });        

        this.category = this.$store.getters['categories/category'];
        this.dataHasBeenSet = true;
        this.isFavorite = 0;
        this.max_productname_len = 0;

      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    updateLocalItemQuantity(item, quantity) {
      // console.log('updateLocalItemQuantity called:', item, quantity);

      const localItemIndex = this.localItems.findIndex(function(entry) {
        // console.log(entry.item, item);
        return entry.item.item_code === item.item_code
      });

      if (localItemIndex === -1) { // not found
        if (quantity === 0) {
          return;
        }

        this.localItems.push({
          item: item,
          item_code: item.item_code,
          item_quantity: quantity
        });
      } else {
        if (quantity === 0) {
          this.localItems.splice(localItemIndex, 1);
        } else {
          this.localItems[localItemIndex].item_quantity = quantity;
        }
      }
    },
    resetLocalItems() {
      this.localItems = [];
    },

    async markAsFavorite() {
      if (!this.$store.getters['auth/auth']) {
        this.openDialog({
          title: 'Ενημέρωση',
          html: 'Θα πρέπει να είστε συνδεδεμένοι για να προσθέσετε προϊόντα στη λίστα αγαπημένων σας'
        });

        return false;
      }

      try {
        //console.log(this.category.id);

        await this.$store.dispatch('categories/addToFavourites', this.category.id);
        this.loadData();
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    async unmarkAsFavorite() {
      try {
        await this.$store.dispatch('categories/removeFromFavourites', this.category.id);
        this.loadData();
      } catch (error) {
        this.openDialog({
          class: 'danger',
          html: error.message
        });
      }
    },
    /*productInfoClass() {
     return 'product-info';
    },*/

  },
  async mounted() {
    //console.log('GGGGPPPPP');
    //let my_height = this.$refs.productinfo.clientHeight;
    //let my_height = element.offsetHeight;
    //console.log("my_height=" + my_height);
    

    await this.loadData();

    this.productInfoClass = "product-info";
    this.productImgClass = "product-image-container";
    
    /*const myHeight = this.$refs.productinfo.clientHeight;
    if (myHeight<700) {
      this.productInfoClass = "product-info";
      this.productImgClass = "product-image-container";
    }
    else if (myHeight<1200) {
      this.productInfoClass = "product-info-2";
      this.productImgClass = "product-image-container-2";
    }
    else if (myHeight<1750) {
      this.productInfoClass = "product-info-1500";
      this.productImgClass = "product-image-container-1500";
    }
    else if (myHeight<2250) {
      this.productInfoClass = "product-info-2000";
      this.productImgClass = "product-image-container-2000";
    }
    else {
      this.productInfoClass = "product-info-2500";
      this.productImgClass = "product-image-container-2500";
    }*/
    

    this.$refs['relatedCategoriesSlider'].setElements();
    this.$refs['recentlyOrderSlider'].setElements();

    // initialize mobile sliders
    //if (this.$refs['youAlsoNeedSlider']) {
      this.$refs['youAlsoNeedSlider'].setElements();
    //}

    //if (this.$refs['recentlyOrderedSlider']) {
      this.$refs['recentlyOrderedSlider'].setElements();
    //}

    //let my_height = this.$refs.productinfo.clientHeight;
    //let my_height = element.offsetHeight;
    //console.log("my_height=" + my_height);
    //console.log('GGGGPPPPP');
    //this.productInfoClass = "product-info-2";
  },
  
}
</script>

<style scoped>

.brdr-3d {border: 1px solid #EBEADF; }
.brdr-prd {border:1px solid #262626; }

.spacer-after-recently-bought-products {
  height:150px;
}

.spacer-after-related-products {
  height:0px;
}

.dt-product-name {
  font-size:35px;
  line-height:40px;
  padding-right:25%;
  margin-top:5px;
  margin-bottom:40px;
}

.title-related-products,
.title-recently-ordered {
  font-size:33px;
}

@media (max-width:1600px) {
  .dt-product-name {
    font-size: 30px;
    line-height: 35px;
    padding-right: 30px;
  }

  .title-related-products,
  .title-recently-ordered {
    font-size:30px;
  }
  
}


@media (max-width:1400px) {
  .dt-product-name {
    font-size: 25px;
    line-height: 30px;
    padding-right: 20px;
  }

  .title-related-products,
  .title-recently-ordered {
    font-size:25px;
  }
  
}


.blue-hexagon {
  height:40px;
  width:46px;
}

.specs-label {
  position:absolute;
  font-size:12px;
  top:10px;
  left:6px;
}



.img-sticky {
  position:sticky;
  position: -webkit-sticky;
  top:30px;
}

.product-info {
  /*background-image: url("../../assets/svg/product-octagon-bg-dark-border.svg");
  background-size: 100% 100%;*/
  border-radius:15px;
  border:1px solid #212121;
}
/*.product-info-2 {
  background-image: url("../../assets/svg/product-octagon-bg-dark-border-2.svg");
  background-size: 100% 100%;
}
.product-info-1500 {
  background-image: url("../../assets/svg/product-octagon-bg-dark-border-1500.svg");
  background-size: 100% 100%;
}
.product-info-2000 {
  background-image: url("../../assets/svg/product-octagon-bg-dark-border-2000.svg");
  background-size: 100% 100%;
}
.product-info-2500 {
  background-image: url("../../assets/svg/product-octagon-bg-dark-border-2500.svg");
  background-size: 100% 100%;
}*/

.product-image-container {
  /*background-image: url("../../assets/svg/product-octagon-bg-light-border-lightgray-fill.svg"); 
  background-size: 100% 100%;*/
  /*position:relative;*/
  border-radius:15px;
  border:1px solid #ccc;
  background-color:#f0f0f0; /** f5f5e9 */

}

.product-image-container-white {
  /*background-image: url("../../assets/svg/product-octagon-bg-light-border-lightgray-fill.svg"); 
  background-size: 100% 100%;*/
  /*position:relative;*/
  border-radius:15px;
  border:1px solid #ccc;
  background-color:#fff;
  padding-top:30px;

}


.product-image-container-2 {
  background-image: url("../../assets/svg/product-octagon-bg-light-border-2.svg"); 
  background-size: 100% 100%;
  position:relative;
}
.product-image-container-1500 {
  background-image: url("../../assets/svg/product-octagon-bg-light-border-1500.svg"); 
  background-size: 100% 100%;
  position:relative;
}
.product-image-container-2000 {
  background-image: url("../../assets/svg/product-octagon-bg-light-border-2000.svg"); 
  background-size: 100% 100%;
  position:relative;
}
.product-image-container-2500 {
  background-image: url("../../assets/svg/product-octagon-bg-light-border-2500.svg"); 
  background-size: 100% 100%;
  position:relative;
}

.gp-heart {
  /*margin-top:7px;*/
  position:absolute;
  top:20px;
  right:20px;
}


@media screen and (max-width: 800px) {
  .specs-mobile {top:415px !important;}
}

@media screen and (max-width: 600px) {


.prdcts-brdr{min-height: unset !important;}
.gp-heart {
  position:absolute;
  top:250px;
  right:32px;
  z-index:1;
}
  .rlt-mob{background-color:unset !important;}
  .prdcts-brdr{
    margin-left: 16px;
    margin-right: 16px; }

.ttl-mob {
  padding-top: 60px;
}
.relative{top: 600px;}
.brdr-3d {
  border:unset;
  background-image: url("../../assets/svg/octagon-light-mob.svg"); 
  background-size: 100% 100%;
  position:relative; margin-left:16px; margin-right:16px;  }
.brdr-prd {margin-left:16px; margin-right:16px;     border-radius: 10px;
  background-image: url("../../assets/svg/octagon-dark-mob.svg"); 
  background-size: 100% 100%; border:unset;}
}

@media screen and (max-width: 640px) {
  .text-31{font-size:29px !important;}
}


@media screen and (max-width: 600px) {
  .slidr-title{font-size:24px !important; line-height: 25px !important; margin-bottom: 0px !important;}
}


@media screen and (max-width: 440px) {
    .gp-pt-20 {
      padding-top: unset !important;
  }
    .gp-sm-mt-50 {
      margin-top: 80px !important;
  }
}


</style>


<style>

.you-also-need-slider {
  position:relative;
}

@media (max-width:800px) {
.you-also-need-slider .slide,
.recently-ordered-slider .slide {
  /*width:300px;*/
}
}


.recently-ordered-grid {
  grid-auto-columns:25% 25% 25% 25%;
}

.related-categories-slider, 
#slider-navigation {
  margin-top:30px;
  position:relative;
}

.recently-ordered-slider, 
#slider-navigation {
  margin-top:30px;
  position:relative;
}

.specs {
  width:46px;
  height:40px;
  background-image:url(../../assets/svg/specs.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.specs:hover {
  background-image:url(../../assets/svg/specs-black.svg);
}

.gp-back-link {
  padding-left:20px !important;
}


</style>